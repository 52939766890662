// FETCHING JOB LIST
export const FETCHING_JOBS_KEY = "FETCHING_JOBS_KEY";
export const FETCHING_JOBS = "FETCHING_JOBS";
export const FETCH_JOBS_SUCCEED = "FETCH_JOBS_SUCCEED";
export const FETCH_JOBS_FAILED = "FETCH_JOBS_FAILED";
export const CLEAR_JOB_LIST = "CLEAR_JOB_LIST";
export const NO_JOBS_FOUND = "NO_JOBS_FOUND";
export const UPDATE_JOB_ID = "UPDATE_JOB_ID";
export const GET_JOBS_PARAMS = "GET_JOBS_PARAMS";
export const FETCHING_ALL_JOBS = "FETCHING_ALL_JOBS";
export const UPDATE_FETCHING_ALL_JOBS = "UPDATE_FETCHING_ALL_JOBS";
export const UPDATE_JOBS_LIST_PAGE = "UPDATE_JOBS_LIST_PAGE";
export const UPDATE_LOADING_JOBS = "UPDATE_LOADING_JOBS";
export const TRIGGER_JOB_RELOAD = "TRIGGER_JOB_RELOAD";
export const UPDATE_HOVER_JOB = "UPDATE_HOVER_JOB";

// FOR JOB SUGGESTION
export const FETCH_JOB_SUGGESTION_KEY = "FETCH_JOB_SUGGESTION_KEY";
export const FETCH_JOB_SUGGESTION = "FETCH_JOB_SUGGESTION";
export const FETCH_JOB_SUGGESTION_SUCCEED = "FETCH_JOB_SUGGESTION_SUCCEED";
export const FETCH_JOB_SUGGESTION_FAILED = "FETCH_JOB_SUGGESTION_FAILED";
export const JOB_SUGGESTION_END = "JOB_SUGGESTION_END";
export const CLEAR_JOB_SUGGESTION = "CLEAR_JOB_SUGGESTION";

// FOR MICROSITE JOB LIST
export const FETCH_MICROSITE_JOBS_SUCCEED = "FETCH_MICROSITE_JOBS_SUCCEED";
export const FETCH_MICROSITE_JOBS_FAILED = "FETCH_MICROSITE_JOBS_FAILED";

// FOR CAMPAIGN JOB LIST
export const FETCH_CAMPAIGN_JOBS = "FETCH_CAMPAIGN_JOBS";
export const FETCH_CAMPAIGN_JOBS_SUCCEED = "FETCH_CAMPAIGN_JOBS_SUCCEED";
export const FETCH_CAMPAIGN_JOBS_FAILED = "FETCH_CAMPAIGN_JOBS_FAILED";

// FOR JOB LIST FILTER
export const UPDATE_JOB_LIST_FILTER = "UPDATE_JOB_LIST_FILTER";
export const KEYWORD_KEY = "keyword";

// GET JOB
export const GET_JOB_KEY = "GET_JOB_KEY";
export const GET_JOB = "GET_JOB";
export const GET_JOB_SUCCEED = "GET_JOB_SUCCEED";
export const GET_JOB_FAILED = "GET_JOB_FAILED";
export const END_OF_JOB_LIST = "END_OF_JOB_LIST";

// GET BULK JOB
export const GET_BULK_JOB = "GET_BULK_JOB";
export const GET_BULK_JOB_SUCCEED = "GET_BULK_JOB_SUCCEED";
export const GET_BULK_JOB_FAILED = "GET_BULK_JOB_FAILED";

// GET JOB BOOKMARK AND APPLY STATUS
export const GET_JOB_BOOKMARK_AND_APPLY_STATUS =
  "GET_JOB_BOOKMARK_AND_APPLY_STATUS";
export const GET_JOB_BOOKMARK_AND_APPLY_STATUS_SUCCEED =
  "GET_JOB_BOOKMARK_AND_APPLY_STATUS_SUCCEED";
export const GET_JOB_BOOKMARK_AND_APPLY_STATUS_FAILED =
  "GET_JOB_BOOKMARK_AND_APPLY_STATUS_FAILED";

// BOOKMARK JOB
export const BOOKMARK_JOB = "BOOKMARK_JOB";
export const BOOKMARK_JOB_SUCCEED = "BOOKMARK_JOB_SUCCEED";
export const BOOKMARK_JOB_FAILED = "BOOKMARK_JOB_FAILED";

// UNBOOKMARK JOB
export const UNBOOKMARK_JOB = "UNBOOKMARK_JOB";
export const UNBOOKMARK_JOB_SUCCEED = "UNBOOKMARK_JOB_SUCCEED";
export const UNBOOKMARK_JOB_FAILED = "UNBOOKMARK_JOB_FAILED";

// APPLY JOB
export const APPLY_JOB = "APPLY_JOB";
export const APPLY_JOB_SUCCEED = "APPLY_JOB_SUCCEED";
export const APPLY_JOB_FAILED = "APPLY_JOB_FAILED";

// BULK APPLY JOB
export const APPLY_JOBS = "APPLY_JOBS";
export const APPLY_JOBS_SUCCEED = "APPLY_JOBS_SUCCEED";
export const APPLY_JOBS_FAILED = "APPLY_JOBS_FAILED";

// CANCEL JOB APPLICATION
export const CANCELLING_JOB_APPLICATION = "CANCELLING_JOB_APPLICATION";
export const CANCEL_JOB_APPLICATION_SUCCEED = "CANCEL_JOB_APPLICATION_SUCCEED";
export const CANCEL_JOB_APPLICATION_FAILED = "CANCEL_JOB_APPLICATION_FAILED";

//GET SPECIALIZATION
export const GET_SPECIALISATION = "GET_SPECIALISATION";
export const GET_SPECIALISATION_SUCCEED = "GET_SPECIALISATION_SUCCEED";
export const GET_SPECIALISATION_FAILED = "GET_SPECIALISATION_FAILED";

// GET TRACKS
export const GET_TRACK = "GET_TRACK";
export const GET_TRACK_SUCCEEDED = "GET_TRACK_SUCCEEDED";
export const GET_TRACK_FAILED = "GET_TRACK_FAILED";

// GET STATE REGION
export const GET_STATEREGION = "GET_STATEREGION";
export const GET_STATEREGION_SUCCEEDED = "GET_STATEREGION_SUCCEEDED";
export const GET_STATEREGION_FAILED = "GET_STATEREGION_FAILED";

// FETCHING FEATURED_JOBS
export const FETCHING_FEATURED_JOBS = "FETCHING_FEATURED_JOBS";
export const FETCH_FEATURED_JOBS_SUCCEED = "FETCH_FEATURED_JOBS_SUCCEEDED";
export const FETCH_FEATURED_JOBS_FAILED = "FETCH_FEATURED_JOBS_FAILED";

// FETCHING CURATED_JOBS
export const FETCHING_CURATED_JOBS = "FETCHING_CURATED_JOBS";
export const FETCH_CURATED_JOBS_SUCCEED = "FETCH_CURATED_JOBS_SUCCEEDED";
export const FETCH_CURATED_JOBS_FAILED = "FETCH_CURATED_JOBS_FAILED";

// GET JOB COMPANY JOBS
export const GET_JOB_COMPANY_JOBS = "GET_JOB_COMPANY_JOBS";
export const GET_JOB_COMPANY_JOB_SUCCEED = "GET_JOB_COMPANY_JOB_SUCCEED";
export const GET_JOB_COMPANY_JOB_FAILED = "GET_JOB_COMPANY_JOB_FAILED";

// GET JOB TYPES
export const GET_JOB_TYPE = "GET_JOB_TYPE";
export const GET_JOB_TYPE_SUCCEEDED = "GET_JOB_TYPE_SUCCEEDED";
export const GET_JOB_TYPE_FAILED = "GET_JOB_TYPE_FAILED";

// UPDATE SELECTED JOB
export const UPDATE_SELECTED_JOB = "UPDATE_SELECTED_JOB";

// GET EXPERIENCE LEVELS
export const GET_EXPERIENCE_LEVEL = "GET_EXPERIENCE_LEVEL";
export const GET_EXPERIENCE_LEVEL_SUCCEEDED = "GET_EXPERIENCE_LEVEL_SUCCEEDED";
export const GET_EXPERIENCE_LEVEL_FAILED = "GET_EXPERIENCE_LEVEL_FAILED";

// FETCHING BOOKMARK JOB LIST
export const FETCH_JOB_BOOKMARK_LIST = "FETCH_JOB_BOOKMARK_LIST";
export const FETCH_JOB_BOOKMARK_LIST_SUCCEED =
  "FETCH_JOB_BOOKMARK_LIST_SUCCEED";
export const FETCH_JOB_BOOKMARK_LIST_FAILED = "FETCH_JOB_BOOKMARK_LIST_FAILED";
export const CLEAR_JOB_BOOKMARK_LIST = "CLEAR_JOB_BOOKMARK_LIST";

// FOR UPDATING BOOKMARKED JOB IN BOOKMARK JOB LIST
export const UPDATE_BOOKMARKED_JOB = "UPDATE_BOOKMARKED_JOB";

// BULK APPLY JOBS
export const BULK_APPLY_JOBS = "BULK_APPLY_JOBS";
export const BULK_APPLY_JOBS_SUCCEED = "BULK_APPLY_JOBS_SUCCEED";
export const BULK_APPLY_JOBS_FAILED = "BULK_APPLY_JOBS_FAILED";
export const CLEAR_BULK_APPLIED_JOBS = "CLEAR_BULK_APPLIED_JOBS";

// FETCHING APPLIED JOB LIST
export const FETCHING_APPLIED_JOBS = "FETCHING_APPLIED_JOBS";
export const FETCH_APPLIED_JOBS_SUCCEED = "FETCH_APPLIED_JOBS_SUCCEED";
export const FETCH_APPLIED_JOBS_FAILED = "FETCHING_APPLIED_JOBS_FAILED";
export const CLEAR_APPLIED_JOBS = "CLEAR_APPLIED_JOBS";

// FETCHING PENDING JOB LIST
export const FETCHING_PENDING_JOBS = "FETCHING_PENDING_JOBS";
export const FETCH_PENDING_JOBS_SUCCEED = "FETCH_PENDING_JOBS_SUCCEED";
export const FETCH_PENDING_JOBS_FAILED = "FETCHING_PENDING_JOBS_FAILED";
export const CLEAR_PENDING_JOBS = "CLEAR_PENDING_JOBS";

// UPDATE JOB LIST FILTER ON LANDING PAGE
export const UPDATE_JOB_LIST_FILTER_LANDING = "UPDATE_JOB_LIST_FILTER_LANDING";

// FETCHING RECOMMENDED JOB LIST
export const FETCHING_RECOMMENDED_JOBS = "FETCHING_RECOMMENDED_JOBS";
export const FETCH_RECOMMENDED_JOBS_SUCCEED = "FETCH_RECOMMENDED_JOBS_SUCCEED";
export const FETCH_RECOMMENDED_JOBS_FAILED = "FETCH_RECOMMENDED_JOBS_FAILED";
export const UPDATE_RECOMMENDED_JOBS = "UPDATE_RECOMMENDED_JOBS";

// FETCHING ONBOARDING RECOMMENDED JOB LIST
export const FETCHING_ONBOARDING_RECOMMENDED_JOBS =
  "FETCHING_ONBOARDING_RECOMMENDED_JOBS";
export const FETCH_ONBOARDING_RECOMMENDED_JOBS_SUCCEED =
  "FETCH_ONBOARDING_RECOMMENDED_JOBS_SUCCEED";
export const FETCH_ONBOARDING_RECOMMENDED_JOBS_FAILED =
  "FETCH_ONBOARDING_RECOMMENDED_JOBS_FAILED";

// FETCHING SIMILAR JOBS UNDER JOB PAGE
export const FETCH_SIMILAR_JOBS_UNDER_JOB_PAGE_SUCCEED =
  "FETCH_SIMILAR_JOBS_UNDER_JOB_PAGE_SUCCEED";
export const FETCH_SIMILAR_JOBS_UNDER_JOB_PAGE_FAILED =
  "FETCH_SIMILAR_JOBS_UNDER_JOB_PAGE_FAILED";
export const FETCHING_SIMILAR_JOBS_UNDER_JOB_PAGE =
  "FETCHING_SIMILAR_JOBS_UNDER_JOB_PAGE";

// FETCHING HOMEPAGE TRENDING KEYWORDS
export const FETCHING_HOMEPAGE_TRENDING_KEYWORDS =
  "FETCHING_HOMEPAGE_TRENDING_KEYWORDS";
export const FETCH_HOMEPAGE_TRENDING_KEYWORDS_SUCCEED =
  "FETCH_HOMEPAGE_TRENDING_KEYWORDS_SUCCEED";
export const FETCH_HOMEPAGE_TRENDING_KEYWORDS_FAILED =
  "FETCH_HOMEPAGE_TRENDING_KEYWORDS_FAILED";

// FOR JOB ALERTS
export const UPDATE_JOB_ALERT_TOGGLE = "UPDATE_JOB_ALERT_TOGGLE";

export const FETCHING_JOB_ALERTS = "FETCHING_JOB_ALERTS";
export const FETCH_JOB_ALERTS_SUCCEED = "FETCH_JOB_ALERTS_SUCCEED";
export const FETCH_JOB_ALERTS_FAILED = "FETCH_JOB_ALERTS_FAILED";

export const CREATING_JOB_ALERT = "CREATING_JOB_ALERT";
export const CREATE_JOB_ALERT_SUCCEED = "CREATE_JOB_ALERT_SUCCEED";
export const CREATE_JOB_ALERT_FAILED = "CREATE_JOB_ALERT_FAILED";
export const REACHED_MAX_JOB_ALERTS = "REACHED_MAX_JOB_ALERTS";
export const JOB_ALERT_EXISTS = "JOB_ALERT_EXISTS";

export const DELETING_JOB_ALERT = "DELETING_JOB_ALERT";
export const DELETE_JOB_ALERT_SUCCEED = "DELETE_JOB_ALERT_SUCCEED";
export const DELETE_JOB_ALERT_FAILED = "DELETE_JOB_ALERT_SUCCEED";

export const REPLACING_JOB_ALERT = "REPLACING_JOB_ALERT";
export const REPLACE_JOB_ALERT_SUCCEED = "REPLACE_JOB_ALERT_SUCCEED";
export const REPLACE_JOB_ALERT_FAILED = "REPLACE_JOB_ALERT_FAILED";

export const UPDATING_JOB_ALERT = "UPDATING_JOB_ALERT";
export const UPDATE_JOB_ALERT_SUCCEED = "UPDATE_JOB_ALERT_SUCCEED";
export const UPDATE_JOB_ALERT_FAILED = "UPDATE_JOB_ALERT_FAILED";

export const GET_JOB_ALERT = "GET_JOB_ALERT";
export const GET_JOB_ALERT_SUCCEED = "GET_JOB_ALERT_SUCCEED";
export const GET_JOB_ALERT_FAILED = "GET_JOB_ALERT_FAILED";

export const VISITOR_ADDING_JOB_ALERT = "VISITOR_ADDING_JOB_ALERT";
export const VISITOR_ADD_JOB_ALERT_SUCCEED = "VISITOR_ADD_JOB_ALERT_SUCCEED";
export const VISITOR_ADD_JOB_ALERT_FAILED = "VISITOR_ADD_JOB_ALERT_FAILED";

export const VISITOR_UNSUBSCRIBING_JOB_ALERT =
  "VISITOR_UNSUBSCRIBING_JOB_ALERT";
export const VISITOR_UNSUBSCRIBE_JOB_ALERT_SUCCEED =
  "VISITOR_UNSUBSCRIBE_JOB_ALERT_SUCCEED";
export const VISITOR_UNSUBSCRIBE_JOB_ALERT_FAILED =
  "VISITOR_UNSUBSCRIBE_JOB_ALERT_FAILED";

export const CLEAR_JOB_ALERTS = "CLEAR_JOB_ALERTS";

// FOR SEARCH DATA AND SUGGESTIONS
export const FETCHING_SEARCH_DATA = "FETCHING_SEARCH_DATA";
export const FETCH_SEARCH_DATA_SUCCEED = "FETCH_SEARCH_DATA_SUCCEED";
export const FETCH_SEARCH_DATA_FAILED = "FETCH_SEARCH_DATA_SUGGESTIONS_FAILED";
export const UPDATE_SEARCH_TYPE = "UPDATE_SEARCH_TYPE";

export const CLEAR_SEARCH_SUGGESTIONS = "CLEAR_SEARCH_SUGGESTIONS";

// UPDATE SELECT ALL JOB FILTER
export const UPDATE_SELECT_ALL_JOB_FILTER = "UPDATE_SELECT_ALL_JOB_FILTER";

//UNSAVE_JOB
export const UNSAVE_JOB = "UNSAVE_JOB";

//SAVE_JOB
export const SAVE_JOB = "SAVE_JOB";

// FOR ASHLEY TOGGLE DIALOG
export const UPDATE_ASHLEY_TOGGLE_DIALOG = "UPDATE_ASHLEY_TOGGLE_DIALOG";

export const FETCHING_FOR_YOU = "FETCHING_FOR_YOU";

// FOR ASHLEY RECOMMENDED JOBS
export const FETCHING_ASHLEY_RECOMMENDED_JOBS =
  "FETCHING_ASHLEY_RECOMMENDED_JOBS";
export const FETCH_ASHLEY_RECOMMENDED_JOBS_SUCCEED =
  "FETCH_ASHLEY_RECOMMENDED_JOBS_SUCCEED";
export const FETCH_ASHLEY_RECOMMENDED_JOBS_FAILED =
  "FETCH_ASHLEY_RECOMMENDED_JOBS_FAILED";
// FOR ASHLEY PERSONALISED JOBS
export const FETCHING_ASHLEY_AWS_RECOMMENDED_JOBS =
  "FETCHING_ASHLEY_AWS_RECOMMENDED_JOBS";
export const FETCH_ASHLEY_AWS_RECOMMENDED_JOBS_SUCCEED =
  "FETCH_ASHLEY_AWS_RECOMMENDED_JOBS_SUCCEED";
export const FETCH_ASHLEY_AWS_RECOMMENDED_JOBS_FAILED =
  "FETCH_ASHLEY_AWS_RECOMMENDED_JOBS_FAILED";

export const CLOSE_ASHLEY_ERROR_DIALOG = "CLOSE_ASHLEY_ERROR_DIALOG";

// HOMEPAGE LOGO
export const FETCH_HOMEPAGE_LOGO = "FETCH_HOMEPAGE_LOGO";

// INTERNSHIP BUTTON FILTER
export const UPDATE_INTERNSHIP_FILTER_BUTTON_STATUS =
  "UPDATE_INTERNSHIP_FILTER_BUTTON_STATUS";
export const FETCH_TRENDING_CHIP = "FETCH_TRENDING_CHIP";

// HOMEPAGE ADS SLIDER
export const FETCH_HOMEPAGE_ADS = "FETCH_HOMEPAGE_ADS";
export const UPDATE_JOB_ALERT_EMAIL = "UPDATE_JOB_ALERT_EMAIL";

// Visitor Job Alert
export const SHOW_VISITOR_JOB_ALERT_DIALOG = "SHOW_VISITOR_JOB_ALERT_DIALOG";
export const SHOW_VISITOR_JOB_ALERT_SUCCESS = "SHOW_VISITOR_JOB_ALERT_SUCCESS";

export const UPDATE_FOR_YOU_PAGINATION_COUNT =
  "UPDATE_FOR_YOU_PAGINATION_COUNT";

// Recent viewed jobs
export const FETCHING_RECENT_VIEWED_JOBS = "FETCHING_RECENT_VIEWED_JOBS";
export const FETCH_RECENT_VIEWED_JOBS_SUCCEED =
  "FETCH_RECENT_VIEWED_JOBS_SUCCEED";
export const FETCH_RECENT_VIEWED_JOBS_FAILED =
  "FETCH_RECENT_VIEWED_JOBS_FAILED";

// SINGLE JOB PAGE FOLLOW COMPANY
export const FOLLOW_SINGLE_JOB_COMPANY_SUCCEED =
  "FOLLOW_SINGLE_JOB_COMPANY_SUCCEED";
export const FOLLOW_SINGLE_JOB_COMPANY_FAILED =
  "FOLLOW_SINGLE_JOB_COMPANY_FAILED";

// Secondary Visitor Apply Job
export const SELECTED_JOB_ID = "SELECTED_JOB_ID";

// To manage predefined job filter flow
export const UPDATE_CHECKING_URL_PARAM_FOR_JOB_FILTER_STATUS =
  "UPDATE_CHECKING_URL_PARAM_FOR_JOB_FILTER_STATUS";

export const UPDATE_JOB_SEARCH_KEYWORD = "UPDATE_JOB_SEARCH_KEYWORD";

export const UPDATE_JOB_SEARCH_OPTION_KEYWORD =
  "UPDATE_JOB_SEARCH_OPTION_KEYWORD";

export const UPDATE_SEARCH_FEEDBACK = "UPDATE_SEARCH_FEEDBACK";

// To manage predefined job list tab
export const UPDATE_JOB_LIST_TAB = "UPDATE_JOB_LIST_TAB";

export const UPDATE_FYP_TAB = "UPDATE_FYP_TAB";
export const UPDATE_VIEW_HISTORY = "UPDATE_VIEW_HISTORY";

export const HIDE_JOB = "HIDE_JOB";
export const HIDE_JOB_SUCCESS = "HIDE_JOB_SUCCESS";
export const HIDE_JOB_FAILED = "HIDE_JOB_FAILED";

export const UNDO_HIDE_JOB = "UNDO_HIDE_JOB";
export const UNDO_HIDE_JOB_SUCCESS = "UNDO_HIDE_JOB_SUCCESS";
export const UNDO_HIDE_JOB_FAILED = "UNDO_HIDE_JOB_FAILED";

export const FEEDBACK_HIDE_JOB = "FEEDBACK_HIDE_JOB";
export const FEEDBACK_HIDE_JOB_SUCCESS = "FEEDBACK_HIDE_JOB_SUCCESS";
export const FEEDBACK_HIDE_JOB_FAILED = "FEEDBACK_HIDE_JOB_FAILED";
