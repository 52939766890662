const sendTrackingEvent = (params) => {
  if (typeof dataLayer === "undefined") return;
  try {
    dataLayer?.push(params);
  } catch (error) {
    // tracking error
  }
};

const signupTracking = (url, origin, job, company) => {
  let signupMedium = "internal";
  let signupSource = "";

  if (url.includes("/apply")) {
    if (origin === "search") {
      signupSource = job.category
        ? `jlp_easy_apply_${job.category}`
        : signupSource;
    } else if (origin === "individual_job_page") {
      signupSource = job.category
        ? `jdp_easy_apply_${job.category}`
        : signupSource;
    } else {
      signupSource = "";
    }
  } else if (url.includes("/companies")) {
    signupSource = "clp_navbar";

    if (Object.keys(company).length > 0) {
      signupSource = company.category
        ? `cdp_navbar_${company.category}`
        : signupSource;
    }
  } else if (url.includes("/jobs")) {
    signupSource = "jlp_navbar";
    if (Object.keys(job).length > 0) {
      signupSource = job.category ? `jdp_navbar_${job.category}` : signupSource;
    }
  } else if (url.includes("/")) {
    signupSource = "hp_navbar";
  }

  if (origin === "job_recommendation") {
    signupSource = "jlp_fyp";
  }

  if (url.includes("/advice")) {
    signupMedium = "";
    signupSource = "";
  }

  return { signupMedium, signupSource };
};

export { sendTrackingEvent, signupTracking };
